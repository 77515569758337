import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { CustomDot } from "../Reusable/dot-line"
import styled from "styled-components"
import { IdComponent } from "../Reusable/id-components"
import { BackgroundImg } from "./HowToUse"
import bgGrey from "../../images/logo-big-grey.svg"

function BlogContainer({ isEnglish }) {
  const data = useStaticQuery(graphql`
    {
      idBlogs: allStrapiMainpageIdBlogs {
        nodes {
          id
          idBlogTitle
          idBlogDesc
          idBlogDate
          idBlogLink
          idBlogImage {
            childImageSharp {
              fluid(quality: 30) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      enBlogs: allStrapiMainpageEnBlogs {
        nodes {
          id
          enBlogTitle
          enBlogDesc
          enBlogDate
          enBlogLink
          enBlogImage {
            childImageSharp {
              fluid(quality: 30) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const Datas = isEnglish ? data.enBlogs.nodes : data.idBlogs.nodes
  const TitleText = isEnglish ? "Newest Article" : "Artikel terbaru"
  const ReadMore = isEnglish ? `Read More →` : `Lihat →`
  return (
    <>
      <IdComponent title="blog" />
      <CustomContainer>
        <MainTitle>{TitleText}</MainTitle>
        <Row className="mt-4">
          {Datas.map(data => {
            const srcImg = isEnglish
              ? data.enBlogImage.childImageSharp.fluid
              : data.idBlogImage.childImageSharp.fluid
            const date = isEnglish ? data.enBlogDate : data.idBlogDate
            const link = isEnglish ? data.enBlogLink : data.idBlogLink
            const title = isEnglish ? data.enBlogTitle : data.idBlogTitle
            const desc = isEnglish ? data.enBlogDesc : data.idBlogDesc
            return (
              <CustomCol lg={4} key={data.id}>
                <CardDiv>
                  <div>
                    <DateDiv>
                      <DateText>{date}</DateText>
                    </DateDiv>
                    <ImageBlog fluid={srcImg} alt="blog-image" />
                  </div>
                  <CardTextDiv>
                    <BlogTitleDiv>
                      <LinkTitle href={link} target="_blank">
                        <BlogTitle>{title}</BlogTitle>
                      </LinkTitle>
                    </BlogTitleDiv>
                    <BlogDescDiv>
                      <BlogDesc>{desc}</BlogDesc>
                    </BlogDescDiv>
                  </CardTextDiv>
                  <LinkBlog href={link} target="_blank">
                    <p>{ReadMore}</p>
                  </LinkBlog>
                </CardDiv>
              </CustomCol>
            )
          })}
        </Row>
        <BackgroundImg
          src={bgGrey}
          alt="logo"
          width="500px"
          top="-150px"
          left="-300px"
          rotate="180deg"
        />
        {/* <HrBlog /> */}
      </CustomContainer>
    </>
  )
}

export default BlogContainer

const CustomCol = styled(Col)`
  @media (max-width: 992px) {
    margin-bottom: 24px;
  }
`

const MainTitle = styled.h2`
  color: #102c57;
  font-family: "Typefez-black";
  font-size: 50px;
  margin-bottom: 3rem;
  text-align: center;
`

const CustomContainer = styled(Container)`
  margin-top: 4rem;
  margin-bottom: 3rem;
  position: relative;
  @media (max-width: 576px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`

const CardDiv = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 3;
  height: 450px;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
`

const CardTextDiv = styled.div`
  padding: 24px 28px;
  position: relative;
`

const BlogTitleDiv = styled.div`
  margin-bottom: 24px;
  position: absolute;
  width: 80%;
`

const BlogTitle = styled.h3`
  padding: 0px 0px;
  color: #0f2c57;
  font-size: 20px;
  font-family: "Typefez-extrabold";
  @media (max-width: 576px) {
    font-size: 18px;
  }
`

const BlogDescDiv = styled.div`
  position: absolute;
  width: 80%;
  top: 100px;
`

const BlogDesc = styled.p`
  font-size: 14px;
  font-family: "Typefez-med";
  text-align: left;
  color: #333334;
`

const ImageBlog = styled(Img)`
  width: 100%;
  height: 200px !important;
  position: relative;
  z-index: -1;
`

const DateDiv = styled.div`
  background-color: #2ebcd0;
  /* border-bottom-right-radius: 15px; */
  width: 45%;
  margin-bottom: -10%;
  @media (max-width: 992px) {
    width: 50%;
  }
`

const DateText = styled.p`
  font-family: "Typefez-bold";
  font-size: 13px;
  padding: 5px 15px;
  margin-bottom: 0;
  color: white;
`
const LinkBlog = styled.a`
  position: absolute;
  bottom: 8px;
  left: 28px;
  > p {
    text-decoration: underline;
    color: #db64a0;
    font-size: 14px;
    font-family: "Typefez-med";
  }
`

const LinkTitle = styled.a`
  :hover {
    text-decoration: none;
  }
`

const HrBlog = styled.hr`
  margin-top: 2rem;
`
