import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useLocation } from "@reach/router"
import IconBig from "../../images/testimoni-icon-big.svg"
import IconSmall from "../../images/testimoni-icon-small.svg"
import RatingStar from "../../images/rating-star.svg"
import BgDot from "../../images/bg-dot.png"
import {
  TestimoniesNextArrow,
  TestimoniesPrevArrow,
} from "../Reusable/slick-arrow"
import { IdComponent } from "../Reusable/id-components"
import { BackgroundImg } from "./HowToUse"
import bgGreyOutline from "../../images/logo-big-grey-outline.svg"

function Testimonies() {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  const data = useStaticQuery(graphql`
    {
      enTesti: allStrapiTestimonialEns {
        nodes {
          id
          testimonialEnTitle
          testimonialEnName
          testimonialEnBody
          testimonialEnImage {
            childImageSharp {
              fluid(quality: 20) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      idTesti: allStrapiTestimonialIds {
        nodes {
          id
          testimonialIdTitle
          testimonialIdName
          testimonialIdBody
          testimonialIdImage {
            childImageSharp {
              fluid(quality: 20) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const Datas = isEnglish ? data.enTesti.nodes : data.idTesti.nodes
  const termText = isEnglish
    ? "*Actual customer testimonials. Photos are illustrative only."
    : "*Testimoni pengguna asli. Foto hanya ilustrasi."
  const googleText = isEnglish
    ? "Rate 5 stars for Transfez on Google Review"
    : "Bintang 5 untuk Transfez di google review"

  var InitSettings = {
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    infinite: true,
    arrow: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <TestimoniesNextArrow />,
    prevArrow: <TestimoniesPrevArrow />,
    adaptiveHeight: true,
  }

  return (
    <>
      <IdComponent title="testimonies" />
      <CustomDivTestimonies>
        <MainContainer>
          <Slider {...InitSettings}>
            {Datas?.map(data => {
              const testiImg = isEnglish
                ? data?.testimonialEnImage?.childImageSharp?.fluid
                : data?.testimonialIdImage?.childImageSharp?.fluid
              const testiBody = isEnglish
                ? data.testimonialEnBody
                : data.testimonialIdBody
              const testiName = isEnglish
                ? data.testimonialEnName
                : data.testimonialIdName
              return (
                <Row key={data.id} className="d-flex justify-content-start">
                  <CustomColImg md={5}>
                    <div style={{ position: "relative" }}>
                      <ImgOrnament
                        src={IconSmall}
                        alt="ornament"
                        zIndex={0}
                        top="0px"
                        right="-70px"
                        width576="90px"
                        right576="-50px"
                        // width={200}
                      />
                      <Img
                        fluid={testiImg || ""}
                        alt="Testimoni"
                        style={{
                          position: "relative",
                          borderRadius: "50%",
                          width: "200px",
                          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)",
                          // marginLeft: "5%",
                          // border: "5px solid white",
                        }}
                      ></Img>
                      <ImgOrnament
                        src={IconBig}
                        alt="ornament"
                        zIndex="30"
                        left="-120px"
                        bottom="-90px"
                        width={200}
                        width576="170px"
                        left576="-80px"
                        bottom576="-70px"
                      />
                    </div>
                  </CustomColImg>
                  <CustomColText md={7}>
                    <DivText>
                      <CustomH3
                        fs="20px"
                        fs92="16px"
                        pright="70px"
                        ff="Typefez-med"
                      >
                        " {testiBody} "
                      </CustomH3>
                      <div>
                        <CustomH3
                          bottom="100px"
                          bottom768="40px"
                          bottom375="35px"
                        >
                          {testiName}
                        </CustomH3>
                        <CustomH3
                          bottom="80px"
                          bottom768="20px"
                          fs="16px"
                          ff="Typefez-med"
                          fs375="10px"
                        >
                          {googleText}
                        </CustomH3>
                      </div>
                    </DivText>
                  </CustomColText>
                </Row>
              )
            })}
          </Slider>
          <BackgroundImg
            src={bgGreyOutline}
            alt="logo"
            top="200px"
            left="700px"
          />
          <CustomTerm>{termText}</CustomTerm>
        </MainContainer>
      </CustomDivTestimonies>
    </>
  )
}

export default Testimonies

const CustomDivTestimonies = styled.div`
  margin-bottom: 150px;
  @media (max-width: 576px) {
    margin-bottom: 50px;
  }
`

const MainContainer = styled(Container)`
  position: relative;
`

const CustomColImg = styled(Col)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  @media (max-width: 768px) {
    padding-top: 4rem;
    height: auto !important;
    margin-left: 50px;
    /* align-items: flex-end; */
  }
`

const ImgOrnament = styled.img`
  position: absolute;
  width: ${p => p.width};
  z-index: ${p => p.zIndex};
  top: ${p => p.top};
  bottom: ${p => p.bottom};
  left: ${p => p.left};
  right: ${p => p.right};
  @media (max-width: 576px) {
    top: ${p => p.top576};
    bottom: ${p => p.bottom576};
    left: ${p => p.left576};
    right: ${p => p.right576};
    width: ${p => p.width576};
  }
`

const CustomColText = styled(Col)`
  position: relative;
  background: #5a489c;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  @media (max-width: 768px) {
    margin-top: 10%;
    padding-bottom: 160px;
    margin-left: 20px;
    /* padding-left: 5%; */
  }
  @media (max-width: 425px) {
    padding-bottom: 200px;
  }
  @media (max-width: 375px) {
    padding-bottom: 240px;
  }
`

const DivText = styled.div`
  margin: 80px;
  @media (max-width: 992px) {
    margin: 60px;
  }
  @media (max-width: 768px) {
    margin: 30px 60px;
  }
`

const CustomH3 = styled.h3`
  position: absolute;
  top: ${p => p.top};
  bottom: ${p => p.bottom};
  left: ${p => p.left};
  right: ${p => p.right};
  font-family: ${props => props.ff || "Typefez-extrabold"};
  font-size: ${props => props.fs || "22px"};
  color: ${props => props.color || "#FFFFFF"};
  margin-top: ${props => props.mt};
  padding-right: ${p => p.pright};

  @media (max-width: 992px) {
    font-size: ${props => props.fs92 || "14px"};
  }

  @media (max-width: 768px) {
    font-size: ${props => props.fs768 || "14px"};
    margin-top: ${props => props.mtres};
    top: ${p => p.top768};
    bottom: ${p => p.bottom768};
    left: ${p => p.left768};
    right: ${p => p.right768};
  }
  @media (max-width: 425px) {
    font-size: ${props => props.fs375 || "14px"};
    margin-top: ${props => props.mtres};
    top: ${p => p.top375};
    bottom: ${p => p.bottom375};
    left: ${p => p.left375};
    right: ${p => p.right375};
  }
`

const CustomTerm = styled.p`
  /* position: absolute; */
  text-align: end;
  font-family: "Typefez-reg";
  color: #909098;
  font-size: 14px;
  margin-right: 15px;
  margin-top: 10px;
`
