import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import HiwEnImg from "../../images/how-we-work-en.png"
import HiwIdImg from "../../images/how-we-work-id.png"
import HiwStep from "../../images/how-it-works.png"
import Circle from "../../images/bg-circle-half.svg"
import { IdComponent } from "../Reusable/id-components"

export const HowItWorks = ({ props, isEnglish }) => {
  const data = useStaticQuery(graphql`
    query {
      hiwId: file(relativePath: { eq: "how-we-work-id.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hiwEn: file(relativePath: { eq: "how-we-work-en.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const srcImages = isEnglish
    ? data.hiwEn.childImageSharp.fluid
    : data.hiwId.childImageSharp.fluid

  const { Title, Title1, Desc } = props
  return (
    <>
      <IdComponent title="how-it-works" />
      <MainDiv>
        <Container>
          <CustomTitle mb="2rem" fsize="50px">
            {Title}
          </CustomTitle>
          <BoxDiv>
            <CustomImg
              fluid={srcImages}
              alt="how-it-works"
              draggable={false}
              loading="eager"
            />
            <CustomDescription>{Desc}</CustomDescription>
          </BoxDiv>
        </Container>
      </MainDiv>
    </>
  )
}

const MainDiv = styled.div`
  /* background-color: #48a9b5;
  background-image: url(${Circle});
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: bottom 45% left 50%; */
  padding-top: 5rem;
  padding-bottom: 4rem;
`

const CustomTitle = styled.h2`
  font-family: ${p => p.ffamily || "Typefez-black"};
  font-size: ${p => p.fsize};
  text-align: center;
  color: ${p => p.color || "#102C57"};
  margin-top: 0;
  margin-bottom: ${p => p.mb};
  @media (max-width: 576px) {
    font-size: ${p => p.fsizeres};
  }
`

const CustomDescription = styled.p`
  text-align: center;
  font-family: "Typefez-reg";
  color: #fff;
  font-size: 18px;
  margin: 30px 250px 0px 250px;
  @media (max-width: 992px) {
    margin: 50px 10px 0px 10px;
    /* font-size: 14px; */
  }
`

const CustomImg = styled(Img)`
  max-width: 900px;
  margin: auto;
`

const BoxDiv = styled.div`
  text-align: center;
  background-color: #ff7b5a;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  padding: 50px 20px;
  margin: 0px auto;
`
